export default function AuthButton({ disabled = false, children, ...props }) {
    return (
        <button
            {...props}
            className={`px-4 py-2
            bg-astronaut-blue border w-full text-center
            border-transparent rounded-md text-lg text-stone-50 tracking-widest
            hover:drop-shadow-2xl focus:drop-shadow-2xl active:drop-shadow-2xl
            focus:outline-none focus:ring-2 focus:ring-astronaut-blue focus:ring-offset-2
            transition ease-in-out duration-150 disabled:opacity-25 disabled:cursor-not-allowed`}
            disabled={disabled}
        >
            {children}
        </button>
    );
}
